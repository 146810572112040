<template>
    <button type="button" class="uk-button uk-button-default loader-button" disabled>
        <span uk-spinner="ratio: 0.5"></span>
        <span class="uk-margin-small-left">Loading</span>
    </button>
</template>

<script>
export default {
    name: 'LoaderButton'
};
</script>

<style scoped>
.loader-button {
    cursor: not-allowed;
}
</style>
